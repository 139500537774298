import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { useInsightsContext } from 'components/insights/InsightsContainer';
import Export from 'components/insights/layout/details/Export';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import Histogram from 'components/shared/charts/Histogram';
import DataTable from 'components/shared/DataTable';
import { Flags } from 'constants/flags';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../constants';
import Card from '../Card';
import { DataRow, parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';
import useDataFetch from '../helpers/useDataFetch';

import { generateExtendedChartConfig } from './helpers/totalDischargesUtils';
import { ChartData, GroupRow } from './helpers/utils';
import ChartContainer from './ChartContainer';
import MetricDetailContainer from './MetricDetailContainer';
import MetricDetailHeader from './MetricDetailHeader';
import TableContainer from './TableContainer';

const TotalDischargesDetail = () => {
  const { profile } = useInsightsContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [chartData, setChartData] = useState<ChartData>({
    categories: [],
    values: [],
  });
  const [totalDischarges, setTotalDischarges] = useState(-1);
  const [selectedDimension, setSelectedDimension] = useState<string>(DimensionConstants.GROUP_NAME);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
        dimensions: [selectedDimension],
        metrics: [MetricConstants.ID_COUNT],
        sortBy: `${MetricConstants.ID_COUNT} ${SortOrder.DESC}, ${DimensionConstants.GROUP_NAME} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const discharges = metricValues[0];

            if (!groupName && row.grouping === 1) {
              acc.totalDischarges = discharges;
            } else {
              acc.categories.push(groupName || 'None');
              acc.values.push(discharges);
            }

            return acc;
          },
          { categories: [], values: [], totalDischarges: 0 } as {
            categories: string[];
            values: number[];
            totalDischarges: number;
          }
        );

        const { totalDischarges, ...chartData } = parsedData;

        setChartData(chartData);
        setTotalDischarges(totalDischarges);
      },
    }),
    [selectedDimension]
  );

  const { loading } = useDataFetch([request]);

  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);

  const getValueString = () => {
    if (totalDischarges <= 0) return;

    return totalDischarges.toString();
  };

  const tableData = useMemo<GroupRow[]>(() => {
    const { categories, values } = chartData;

    return categories.map((groupName, i) => ({
      groupName,
      discharges: values[i],
    }));
  }, [chartData]);

  const columnHelper = createColumnHelper<GroupRow>();

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      case DimensionConstants.REASON:
        return 'Disposition';
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columns = useMemo<ColumnDef<GroupRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        footer: () => `${totalDischarges} (Total)`,
      }),
    ],
    [columnHelper, getTableHeader, totalDischarges]
  );

  const defaultSort = [
    {
      id: 'discharges',
      desc: true,
    },
  ];

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.values.length}
        header={
          <>
            <MetricDetailHeader label='Total Discharges' loading={loading} value={getValueString()} />
            {profile.hasFlag(Flags.Insights) && !isMobile && (
              <ViewBySelect
                additionalOptions={[
                  {
                    label: 'Disposition',
                    value: DimensionConstants.REASON,
                  },
                ]}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <TableTitleContainer>
          <Card.Title>Total Discharges Overview</Card.Title>
          <Export columns={columns} data={tableData} loading={loading} filePrefix='total-discharges' />
        </TableTitleContainer>
        <DataTable columns={columns} data={tableData} defaultSortBy={defaultSort} loading={loading} />
      </TableContainer>
    </>
  );
};

export default TotalDischargesDetail;
