import { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { DimensionConstants } from 'components/insights/constants';
import GroupType from 'models/GroupType';
import colors from 'styles/theme/colors';

type Props = {
  groupType: GroupType | null;
  onChange: (change) => void;
  additionalOptions?: Array<ViewByOption>;
};

type ViewByOption = {
  label: string;
  value: string;
};

const selectOptions = (groupType: GroupType | null, additionalOptions?: Array<ViewByOption>) => {
  let options = [
    {
      label: 'Episode Type',
      value: DimensionConstants.EPISODE_TYPE,
    },
    {
      label: 'Plan Type',
      value: DimensionConstants.PLAN_TYPE,
    },
    {
      label: `${groupType?.displayName}`,
      value: DimensionConstants.GROUP_NAME,
    },
    {
      label: `${groupType?.displayName} Company`,
      value: DimensionConstants.PROVIDER_CLIENT,
    },
  ] as Array<ViewByOption>;

  options = [...options, ...(additionalOptions ?? [])];

  return options.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
};

const ViewBySelect = ({ groupType, onChange, additionalOptions }: Props) => {
  const defaultSelected = useMemo(() => {
    return {
      label: `${groupType?.displayName}`,
      value: DimensionConstants.GROUP_NAME.toString(),
    };
  }, [groupType?.displayName]);

  const options = selectOptions(groupType, additionalOptions);
  const selectedGroupType = useRef(groupType);
  const [selectedOption, setSelectedOption] = useState(defaultSelected);

  useEffect(() => {
    if (selectedGroupType.current !== groupType) {
      selectedGroupType.current = groupType;
      setSelectedOption(defaultSelected);
      onChange(defaultSelected);
    }
  }, [defaultSelected, groupType, onChange]);

  return (
    <SelectContainer>
      <ViewBy>View By</ViewBy>
      <Select
        options={options}
        value={selectedOption}
        getOptionValue={(prop) => prop.value}
        getOptionLabel={(prop) => prop.label}
        onChange={(selectedOption: ViewByOption) => {
          setSelectedOption(selectedOption);
          onChange(selectedOption);
        }}
        styles={customStyles}
        isSearchable={false}
        menuPosition='fixed'
      />
    </SelectContainer>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: 24,
    borderWidth: '1px',
    boxShadow: 'none',
    minHeight: 24,
    fontSize: 12,
    borderColor: colors.black25,
    '&:hover': {
      borderColor: colors.primaryBlue,
      borderWidth: '1px',
    },
    cursor: 'pointer',
    borderSize: 1,
  }),
  dropdownIndicator: (_: any) => ({
    color: colors.black,
    width: '14px',
    marginRight: '4px',
    boxSizing: 'border-box' as any,
    display: 'flex',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '16px',
    textOverflow: 'ellipsis',
    zIndex: 10,
  }),
  container: (provided) => ({
    ...provided,
    width: 124,
  }),
  input: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 23,
    padding: '0 8px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.primaryBlue : 'inherit',
    '&:hover': { backgroundColor: state.isSelected ? colors.primaryBlue : colors.primaryBlue10 },
  }),
};
export default ViewBySelect;

const ViewBy = styled.div`
  font-size: 12px;
  margin-top: 5px;
  margin-right: 8px;
  color: var(--black)
  font-weight: normal;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;
